<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="firmwareManage">
			<template #ops>
				<el-button
					v-click-blur
					type="primary"
					@click="
						() => {
							firmwareDetails = null
							firmwareModalVisible = true
						}
					"
				>
					添加固件
				</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search keyWord="固件名称" width="300px" :search.sync="form.firmwareName"></gc-custom-search>
				<gc-custom-search
					keyWord="OTA设备类型"
					width="300px"
					type="select"
					:search.sync="form.otaDeviceType"
					:search-option="deviceTypeOptions"
					needAllForSearch
				></gc-custom-search>
				<gc-custom-search
					keyWord="固件类型"
					width="300px"
					type="select"
					:search.sync="form.firmwareType"
					:search-option="firmwareTypeOptions"
					needAllForSearch
				></gc-custom-search>
				<gc-custom-search keyWord="前置版本号" width="300px" :search.sync="form.preVersion"></gc-custom-search>
				<gc-custom-search
					keyWord="目标版本号"
					width="300px"
					:search.sync="form.targetVersion"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button
						type="primary"
						@click="
							pagination.currentPage = 1
							findTableList()
						"
						v-click-blur
					>
						查询
					</el-button>
					<el-button @click="handleReset" v-click-blur>重置</el-button>
				</div>
				<gc-column-setting
					storageKey="localcolumn-firmware-manage"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					border
					showPage
					:columns="columnList"
					:table-data="tableData"
					:current-page.sync="pagination.currentPage"
					:total="pagination.total"
					:pageSize="pagination.pageSize"
					gloabSortDisable
					pageLayout="prev, next, jumper, sizes"
					@current-page-change="currentPageChange"
					@size-change="handleSizeChange"
				></gc-table>
			</div>
		</div>
		<FirmwareModal
			v-if="firmwareModalVisible"
			:deviceTypeOptions="deviceTypeOptions"
			:firmwareTypeOptions="firmwareTypeOptions"
			:firmwareDetails="firmwareDetails"
			@close-modal="handleCloseModal"
		/>
	</div>
</template>

<script>
import {
	apiGetFirmwarePageList,
	apiDeleteFirmware,
	apiFirmwareCheckCanUpdate,
	apiGetFirmwareDeviceType,
} from '@/apis/firmware.api'
import { firmwareTypeOptions, firmwareTypeMap } from '@/config/const'
import FirmwareModal from './components/FirmwareModal.vue'

export default {
	name: 'FirmwareManage',
	components: { FirmwareModal },
	data() {
		return {
			form: {
				firmwareName: null,
				otaDeviceType: null,
				firmwareType: null,
				preVersion: null,
				targetVersion: null,
			},
			columns: [
				{
					key: 'firmwareName',
					name: '固件名称',
					tooltip: true,
					dragDisable: true,
					disabledSelect: true,
					minWidth: 180,
				},
				{
					key: 'otaDeviceTypeName',
					name: 'OTA设备类型',
					tooltip: true,
				},
				{
					key: 'firmwareType',
					name: '固件类型',
					tooltip: true,
					render: (h, row) => {
						return h('span', null, firmwareTypeMap[row.firmwareType])
					},
				},
				{
					key: 'preVersion',
					name: '前置版本号',
					tooltip: true,
				},
				{
					key: 'targetVersion',
					name: '目标版本号',
					tooltip: true,
				},
				{
					key: 'firmwareDescribe',
					name: '固件描述',
					tooltip: true,
				},
				{
					key: 'createStaffName',
					name: '创建人',
				},
				{
					key: 'createTime',
					name: '创建时间',
					minWidth: 180,
					tooltip: true,
				},
				{
					key: 'operationName',
					name: '操作人',
				},
				{
					key: 'oprate',
					name: '操作',
					sortDisable: true,
					width: 130,
					fixed: 'right',
					render: (h, row) => {
						return h('div', {}, [
							h(
								'el-button',
								{
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.handleEditFirmware(row)
										},
									},
								},
								'编辑',
							),
							h(
								'el-button',
								{
									style: { color: '#e5662e' },
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.handleDeleteFirmware(row)
										},
									},
								},
								'删除',
							),
						])
					},
				},
			],
			pagination: {
				currentPage: 1,
				pageSize: 20,
				total: 0,
			},
			tableData: [],
			loading: false,
			deviceTypeOptions: [],
			firmwareTypeOptions,
			firmwareModalVisible: false,
			firmwareDetails: null,
		}
	},

	created() {
		this.findFirmwareDeviceTypeOptions()
		this.findTableList()
	},

	methods: {
		async findTableList() {
			this.loading = true
			const searchParams = {
				...this.getRequestParams(),
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetFirmwarePageList(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		async findFirmwareDeviceTypeOptions() {
			const data = await apiGetFirmwareDeviceType(this.tenantId)
			this.deviceTypeOptions = (data || []).map(item => {
				return {
					value: item.otaDeviceType,
					label: item.otaDeviceTypeName,
				}
			})
		},

		/**
		 * 编辑固件之前先获取当前估计能否编辑状态
		 * @param {*} firmwareItem
		 */
		async handleEditFirmware(firmwareItem) {
			const canEdit = await apiFirmwareCheckCanUpdate(firmwareItem.firmwareId)
			if (!canEdit) {
				return this.$message.warning('当前固件已被任务绑定，无法进行编辑')
			}
			this.firmwareDetails = firmwareItem
			this.firmwareModalVisible = true
		},

		/**
		 * 删除固件
		 * @param {*} firmwareItem
		 */
		async handleDeleteFirmware(firmwareItem) {
			await this.$confirm('删除固件后无法恢复，确认删除？')
			await apiDeleteFirmware(firmwareItem.firmwareId)
			this.$message.success('删除成功')
			this.handleReset()
		},

		getRequestParams() {
			return this.form
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},

		handleSizeChange(size) {
			this.pagination.currentPage = 1
			this.pagination.pageSize = size
			this.findTableList()
		},

		handleReset() {
			for (const key in this.form) {
				this.form[key] = null
			}
			this.pagination.currentPage = 1
			this.findTableList()
		},

		handleCloseModal(refresh) {
			this.firmwareModalVisible = false
			if (refresh) {
				this.handleReset()
			}
		},
	},

	computed: {
		columnList() {
			return this.columns.filter(o => o.selected)
		},

		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../run-manage/runLayout.scss';
</style>
