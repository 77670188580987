import request from '@/utils/request'

export const apiGetFirmwarePageList = data => {
	return request({
		url: '/ota/firmware/list',
		method: 'POST',
		data,
	})
}

export const apiGetDevicePageList = data => {
	return request({
		url: '/ota/device/list',
		method: 'POST',
		data,
	})
}

export const apiSaveFirmware = data => {
	return request({
		url: '/ota/firmware/save',
		method: 'POST',
		data,
	})
}

export const apiUpdateFirmware = data => {
	return request({
		url: '/ota/firmware/update',
		method: 'POST',
		data,
	})
}

export const apiDeleteFirmware = id => {
	return request({
		url: `/ota/firmware/delete?firmwareId=${id}`,
		method: 'GET',
	})
}

export const apiFirmwareCheckCanUpdate = id => {
	return request({
		url: `/ota/firmware/checkCanUpdate?firmwareId=${id}`,
		method: 'GET',
	})
}

export const apiGetFirmwareDeviceType = () => {
	return request({
		url: `/ota/firmware/queryDeviceType`,
		method: 'POST',
	})
}

export const apiGetFirmwareTaskPageList = data => {
	return request({
		url: `/ota/task/taskList`,
		method: 'POST',
		data,
	})
}

export const apiSaveFirmwareTask = data => {
	return request({
		url: `/ota/task/save`,
		method: 'POST',
		data,
	})
}

export const apiCancelFirmwareTask = data => {
	return request({
		url: `/ota/task/cancelTask`,
		method: 'POST',
		data,
	})
}

export const apiCancelFirmwareDetailTask = data => {
	return request({
		url: `/ota/task/cancelDetail`,
		method: 'POST',
		data,
	})
}

export const apiGetFirmwareTaskDetails = taskId => {
	return request({
		url: `/ota/task/detail?taskId=${taskId}`,
		method: 'GET',
	})
}

export const apiGetDeviceDetails = deviceNo => {
	return request({
		url: `/ota/device/detail?deviceNo=${deviceNo}`,
		method: 'GET',
	})
}

export const apiGetSubTaskDetails = data => {
	return request({
		url: `/ota/task/subTaskDetail`,
		method: 'POST',
		data,
	})
}

export const apiGetDeviceTaskDetails = data => {
	return request({
		url: `/ota/device/taskDetail`,
		method: 'POST',
		data,
	})
}

export const apiGetDeviceLogPageList = data => {
	return request({
		url: `/ota/device/deviceLog`,
		method: 'POST',
		data,
	})
}

export const apiDownloadExportFailDetails = data => {
	return request({
		url: `/ota/task/exportErrorMessage`,
		method: 'POST',
		data,
		responseType: 'blob',
	})
}

export const apiDownloadSubTaskExcel = data => {
	return request({
		url: `/ota/task/exportTaskDevice`,
		method: 'POST',
		data,
		responseType: 'blob',
	})
}

export const apiDownloadOtaDevice = data => {
	return request({
		url: `/ota/device/exportOtaDevice`,
		method: 'POST',
		data,
		responseType: 'blob',
	})
}
