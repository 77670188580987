<template>
	<gc-dialog
		:title="firmwareDetails ? '编辑固件' : '添加固件'"
		:show.sync="visible"
		@close="$emit('close-modal')"
		@cancel="$emit('close-modal')"
		@ok="handleSubmitForm"
		width="460px"
	>
		<el-form :model="modelForm" :rules="rules" ref="modelForm" label-position="top" label-width="100px">
			<el-form-item label="固件名称" prop="firmwareName">
				<el-input size="small" v-model="modelForm.firmwareName" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="OTA设备类型" prop="otaDeviceType">
				<el-select v-model="modelForm.otaDeviceType" placeholder="请选择">
					<el-option
						v-for="item in deviceTypeOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="固件类型" prop="firmwareType">
				<el-select v-model="modelForm.firmwareType" placeholder="请选择">
					<el-option
						v-for="item in firmwareTypeOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="前置版本号" prop="preVersion">
				<el-input size="small" v-model="modelForm.preVersion" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="目标版本号" prop="targetVersion">
				<el-input size="small" v-model="modelForm.targetVersion" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="操作人" prop="operationName">
				<el-input size="small" v-model="modelForm.operationName" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="上传文件" prop="fileName">
				<el-upload
					action="#"
					accept=".bin"
					drag
					:auto-upload="false"
					:multiple="false"
					:show-file-list="false"
					:on-change="handleFileChange"
				>
					<div v-if="!modelForm.fileName">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或点击上传</div>
					</div>
					<p v-else class="filename" :title="modelForm.fileName">
						{{ modelForm.fileName }}
					</p>
					<div class="el-upload__tip" slot="tip">只能上传.bin文件，且不超过300kb</div>
				</el-upload>
			</el-form-item>
			<el-form-item label="固件描述" prop="firmwareDescribe">
				<el-input
					size="small"
					v-model="modelForm.firmwareDescribe"
					type="textarea"
					:rows="3"
					resize="none"
					placeholder="请输入"
				></el-input>
			</el-form-item>
		</el-form>
	</gc-dialog>
</template>

<script>
import { apiSaveFirmware, apiUpdateFirmware } from '@/apis/firmware.api'
import { ruleRequired, ruleMaxLength } from '@/utils/rules'
export default {
	name: 'FirmwareModal',
	props: {
		deviceTypeOptions: {
			type: Array,
			default: () => [],
		},
		firmwareTypeOptions: {
			type: Array,
			default: () => [],
		},
		firmwareDetails: {
			type: Object,
			default: () => null,
		},
	},
	data() {
		const versionValidPass = (rule, value, callback) => {
			if (value.trim() === this.modelForm.preVersion.trim()) {
				return callback(new Error('目标版本号不能和前置版本号相同'))
			}
			callback()
		}
		return {
			visible: true,
			modelForm: {
				firmwareName: null,
				firmwareType: 1,
				otaDeviceType: null,
				preVersion: null,
				targetVersion: null,
				operationName: null,
				firmwareDescribe: null,
				fileName: null,
				file: null,
			},
			rules: {
				firmwareName: [ruleRequired(), ruleMaxLength(50)],
				firmwareType: [ruleRequired('请选择')],
				otaDeviceType: [ruleRequired('请选择')],
				fileName: [ruleRequired('请选择')],
				preVersion: [ruleRequired(), ruleMaxLength(50)],
				targetVersion: [ruleRequired(), ruleMaxLength(50), { validator: versionValidPass }],
				operationName: [ruleRequired(), ruleMaxLength(10)],
				firmwareDescribe: [ruleMaxLength(150)],
			},
		}
	},
	created() {
		this.initFirmwareForm()
	},

	methods: {
		initFirmwareForm() {
			if (this.firmwareDetails) {
				const {
					firmwareId,
					firmwareName,
					firmwareType,
					otaDeviceType,
					preVersion,
					targetVersion,
					operationName,
					firmwareDescribe,
					fileName,
				} = this.firmwareDetails
				Object.assign(this.modelForm, {
					firmwareId,
					firmwareName,
					firmwareType: Number(firmwareType),
					otaDeviceType: otaDeviceType,
					preVersion,
					targetVersion,
					operationName,
					firmwareDescribe,
					fileName: `${fileName}.bin`,
				})
			}
		},

		handleSubmitForm() {
			this.$refs.modelForm.validate(async valid => {
				if (valid) {
					const formdata = new FormData()
					const {
						firmwareId,
						firmwareName,
						firmwareType,
						otaDeviceType,
						preVersion,
						targetVersion,
						operationName,
						firmwareDescribe,
						file,
					} = this.modelForm
					firmwareId && formdata.append('firmwareId', firmwareId)
					formdata.append('firmwareName', firmwareName.replace(/^\s+|\s+$/g, ''))
					formdata.append('firmwareType', firmwareType)
					formdata.append('otaDeviceType', otaDeviceType)
					formdata.append('preVersion', preVersion.trim())
					formdata.append('targetVersion', targetVersion.trim())
					formdata.append('operationName', operationName)
					firmwareDescribe && formdata.append('firmwareDescribe', firmwareDescribe)
					file && formdata.append('file', file)
					const API = this.firmwareDetails ? apiUpdateFirmware : apiSaveFirmware
					await API(formdata)
					this.$message.success(this.firmwareDetails ? '更新成功' : '新增成功')
					this.$emit('close-modal', true)
				}
			})
		},

		handleFileChange(file) {
			if (file.size > 300 * 1024) {
				return this.$message.warning('上传的文件大小不能超过300kb')
			}
			this.modelForm.fileName = file.name
			this.modelForm.file = file.raw
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
	.el-upload {
		display: block;
		.el-upload-dragger {
			width: 100%;
			height: 142px;
			.el-icon-upload {
				font-size: 46px;
				margin: 30px 0 0;
			}
			.el-upload__text {
				font-size: 12px;
				line-height: 1;
			}
			.filename {
				margin-top: 52px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding: 0 12px;
			}
		}
	}
	.el-upload__tip {
		margin-top: 0;
	}
}
</style>
